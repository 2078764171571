import { Children as $, cloneElement as v, useState as M, useRef as N, useEffect as O, useCallback as T, useReducer as E } from "react";
import { flushSync as h } from "react-dom";
import { a as U } from "./index-CWsRuimy.js";
import { a as p } from "./index-BFOaq7n_.js";
function I(n, t) {
  return E((o, r) => {
    const i = t[o][r];
    return i ?? o;
  }, n);
}
const P = (n) => {
  const { present: t, children: o } = n, r = S(t), i = typeof o == "function" ? o({
    present: r.isPresent
  }) : $.only(o), c = U(r.ref, i.ref);
  return typeof o == "function" || r.isPresent ? /* @__PURE__ */ v(i, {
    ref: c
  }) : null;
};
P.displayName = "Presence";
function S(n) {
  const [t, o] = M(), r = N({}), i = N(n), c = N("none"), l = n ? "mounted" : "unmounted", [f, a] = I(l, {
    mounted: {
      UNMOUNT: "unmounted",
      ANIMATION_OUT: "unmountSuspended"
    },
    unmountSuspended: {
      MOUNT: "mounted",
      ANIMATION_END: "unmounted"
    },
    unmounted: {
      MOUNT: "mounted"
    }
  });
  return O(() => {
    const e = m(r.current);
    c.current = f === "mounted" ? e : "none";
  }, [
    f
  ]), p(() => {
    const e = r.current, s = i.current;
    if (s !== n) {
      const A = c.current, d = m(e);
      n ? a("MOUNT") : d === "none" || (e == null ? void 0 : e.display) === "none" ? a("UNMOUNT") : a(s && A !== d ? "ANIMATION_OUT" : "UNMOUNT"), i.current = n;
    }
  }, [
    n,
    a
  ]), p(() => {
    if (t) {
      const e = (u) => {
        const d = m(r.current).includes(u.animationName);
        u.target === t && d && h(
          () => a("ANIMATION_END")
        );
      }, s = (u) => {
        u.target === t && (c.current = m(r.current));
      };
      return t.addEventListener("animationstart", s), t.addEventListener("animationcancel", e), t.addEventListener("animationend", e), () => {
        t.removeEventListener("animationstart", s), t.removeEventListener("animationcancel", e), t.removeEventListener("animationend", e);
      };
    } else
      a("ANIMATION_END");
  }, [
    t,
    a
  ]), {
    isPresent: [
      "mounted",
      "unmountSuspended"
    ].includes(f),
    ref: T((e) => {
      e && (r.current = getComputedStyle(e)), o(e);
    }, [])
  };
}
function m(n) {
  return (n == null ? void 0 : n.animationName) || "none";
}
export {
  P as $
};
