import { useRef as s, useEffect as d, useMemo as b, useCallback as h, useState as P } from "react";
function k(e, t, { checkForDefaultPrevented: u = !0 } = {}) {
  return function(c) {
    if (e == null || e(c), u === !1 || !c.defaultPrevented) return t == null ? void 0 : t(c);
  };
}
function a(e) {
  const t = s(e);
  return d(() => {
    t.current = e;
  }), b(
    () => (...u) => {
      var n;
      return (n = t.current) === null || n === void 0 ? void 0 : n.call(t, ...u);
    },
    []
  );
}
function m({ prop: e, defaultProp: t, onChange: u = () => {
} }) {
  const [n, c] = p({
    defaultProp: t,
    onChange: u
  }), f = e !== void 0, i = f ? e : n, r = a(u), $ = h((o) => {
    if (f) {
      const l = typeof o == "function" ? o(e) : o;
      l !== e && r(l);
    } else c(o);
  }, [
    f,
    e,
    c,
    r
  ]);
  return [
    i,
    $
  ];
}
function p({ defaultProp: e, onChange: t }) {
  const u = P(e), [n] = u, c = s(n), f = a(t);
  return d(() => {
    c.current !== n && (f(n), c.current = n);
  }, [
    n,
    c,
    f
  ]), u;
}
export {
  a as $,
  m as a,
  k as b
};
