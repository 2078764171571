import { createContext as x, useMemo as $, createElement as h, useContext as v, useLayoutEffect as P } from "react";
function g(n, r) {
  const o = /* @__PURE__ */ x(r);
  function d(c) {
    const { children: e, ...t } = c, u = $(
      () => t,
      Object.values(t)
    );
    return /* @__PURE__ */ h(o.Provider, {
      value: u
    }, e);
  }
  function s(c) {
    const e = v(o);
    if (e) return e;
    if (r !== void 0) return r;
    throw new Error(`\`${c}\` must be used within \`${n}\``);
  }
  return d.displayName = n + "Provider", [
    d,
    s
  ];
}
function C(n, r = []) {
  let o = [];
  function d(c, e) {
    const t = /* @__PURE__ */ x(e), u = o.length;
    o = [
      ...o,
      e
    ];
    function l(f) {
      const { scope: i, children: p, ...a } = f, m = (i == null ? void 0 : i[n][u]) || t, S = $(
        () => a,
        Object.values(a)
      );
      return /* @__PURE__ */ h(m.Provider, {
        value: S
      }, p);
    }
    function b(f, i) {
      const p = (i == null ? void 0 : i[n][u]) || t, a = v(p);
      if (a) return a;
      if (e !== void 0) return e;
      throw new Error(`\`${f}\` must be used within \`${c}\``);
    }
    return l.displayName = c + "Provider", [
      l,
      b
    ];
  }
  const s = () => {
    const c = o.map((e) => /* @__PURE__ */ x(e));
    return function(t) {
      const u = (t == null ? void 0 : t[n]) || c;
      return $(
        () => ({
          [`__scope${n}`]: {
            ...t,
            [n]: u
          }
        }),
        [
          t,
          u
        ]
      );
    };
  };
  return s.scopeName = n, [
    d,
    w(s, ...r)
  ];
}
function w(...n) {
  const r = n[0];
  if (n.length === 1) return r;
  const o = () => {
    const d = n.map(
      (s) => ({
        useScope: s(),
        scopeName: s.scopeName
      })
    );
    return function(c) {
      const e = d.reduce((t, { useScope: u, scopeName: l }) => {
        const f = u(c)[`__scope${l}`];
        return {
          ...t,
          ...f
        };
      }, {});
      return $(
        () => ({
          [`__scope${r.scopeName}`]: e
        }),
        [
          e
        ]
      );
    };
  };
  return o.scopeName = r.scopeName, o;
}
const E = globalThis != null && globalThis.document ? P : () => {
};
export {
  C as $,
  E as a,
  g as b
};
