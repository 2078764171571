export const Route = {
    HOME: '/',
    LOGIN: '/auth/login',
    LOGOUT: '/api/auth/logout',
    BORROWER_PORTAL: '/portal',
    BORROWER_PORTAL_CONTACT_ONBOARDING: '/portal/[lenderId]/onboarding/contact',
    BORROWER_PORTAL_LENDER: '/portal/[lenderId]',
    BORROWER_PORTAL_EXIT: '/portal/[lenderId]/exit',
    SIGNUP: '/auth/signup',
    FORGOT_PASSWORD: '/auth/forgot-password',
    NEW_PASSWORD: '/auth/new-password',
    PASSWORD_UPDATED: '/auth/password-updated',
    LENDER_HOME: '/lender',
    PRINCIPAL_HOME: '/applicant',
    ANONYMOUS_HOME: '/anonymous',
    ARCHIVE_HOME: '/loans/phases/archive',
    LEAD_HOME: '/loans/phases/lead',
    CALENDAR_HOME: '/calendar',
    COMMUNICATIONS_HOME: '/communications',
    LOANS_TEMPLATES: '/loans/templates',
    ANNOTATOR: '/annotator',
    LOANS_PHASE_CATEGORY: '/loans/phases/[phase]',
    ANALYTICS_HOME: '/charts',
    LOANS_ORIGINATION_HOME: '/loans/phases/origination',
    LOANS_SERVICING_HOME: '/loans/phases/servicing',
    LOANS_PORTFOLIO_HOME: '/loans/phases/portfolio',
    LOANS_LEAD_HOME: '/loans/phases/lead',
    SINGLE_LOAN: '/loans/[loanId]',
    SINGLE_LOAN_MESSAGES: '/loans/[loanId]/messages',
    SINGLE_LOAN_TASKS: '/loans/[loanId]/tasks',
    SINGLE_LOAN_TASKS_TYPE: '/loans/[loanId]/tasks/[type]',
    LOAN_COMPANY_EDIT: '/loans/[loanId]/company/[companyId]/edit',
    LOAN_CONTACT_KEY_CONTACT_CREATE: '/loans/[loanId]/contacts/key-contact/create',
    LOAN_CONTACT_KEY_CONTACT_EDIT: '/loans/[loanId]/contacts/key-contact/[loanRoleId]/edit',
    LOAN_TRUST_EDIT: '/loans/[loanId]/trust/[trustId]/edit',
    LOAN_ASSET_EDIT: '/loans/[loanId]/asset/[assetId]/edit',
    SINGLE_LOAN_TEMPLATE: '/loans/templates/[templateId]',
    CREATE_LOAN: '/loans/create',
    ADMIN_LABELS: '/admin/labels',
    ADMIN_LEARNING_CENTER: '/admin/learning-center',
    ADMIN_LEARNING_CENTER_CREATE: '/admin/learning-center/create',
    ADMIN_LEARNING_CENTER_EDIT: '/admin/learning-center/[id]/edit',
    ADMIN_MAINTENANCE: '/admin/maintenance',
    ADMIN_PAGE: '/admin',
    ADMIN_CREATE_LENDER_PAGE: '/admin/lender/create',
    ADMIN_CREATE_COMPANY_PAGE: '/admin/company/create',
    ADMIN_EDIT_LENDER_PAGE: '/admin/lender/[companyId]/[userId]/edit',
    ADMIN_EDIT_COMPANY_PAGE: '/admin/company/[companyId]/edit',
    CREATE_LOAN_TEMPLATE: '/loans/templates/create',
    EDIT_SINGLE_LOAN: '/loans/[loanId]/edit',
    EDIT_SINGLE_LOAN_TEMPLATE: '/loans/templates/[templateId]/edit',
    CREATE_LOAN_PRINCIPAL: '/loans/[loanId]/persons/create',
    CREATE_LOAN_ENTITY: '/loans/[loanId]/entity/create',
    CREATE_LOAN_COMPANY: '/loans/[loanId]/company/create',
    CREATE_LOAN_TRUST: '/loans/[loanId]/trust/create',
    CREATE_LOAN_APPLICANT: '/loans/[loanId]/applicant/create',
    CREATE_LOAN_ASSET: '/loans/[loanId]/asset/create',
    // v2 templates
    LIST_V2_TEMPLATE: '/v2/templates',
    TEMPLATE_FIELDS: '/admin/fields',
    EDIT_V2_TEMPLATE: '/v2/templates/[templateId]/edit',
    CREATE_V2_TEMPLATE: '/v2/templates/create',
    SINGLE_V2_TEMPLATE: '/v2/templates/[templateId]',
    EDIT_LOAN_PRINCIPAL: '/loans/[loanId]/persons/[personId]/edit',
    ADD_LOAN_LENDER_ROLE: '/loans/[loanId]/lender/create',
    EDIT_LOAN_LENDER_ROLE: '/loans/[loanId]/lender/[roleId]/edit',
    // LEARNING CENTER
    LEARNING_CENTER: '/v2/learning-center',
    LEARNING_CENTER_SINGLE_ITEM: '/v2/learning-center/entry/[slug]',
    LEARNING_CENTER_SINGLE_CATEGORY: '/v2/learning-center/category/[slug]',
    LANDING_PAGE: '/landing',
    AUTH_LOAN_ROLE_VERIFICATION: '/auth/loan-role-verification',
    // Loans explorer
    LOAN_EXPLORER_LOANS: '/explorer/loans',
    LOAN_EXPLORER_LOANS_LOAN_ID: '/explorer/loans/[loanId]',
    LOAN_EXPLORER_LOANS_LOAN_ID_PACKAGE: '/explorer/loans/[loanId]/package',
    LOAN_EXPLORER_LOANS_LOAN_ID_SHOEBOX: '/explorer/loans/[loanId]/shoebox',
    LOAN_EXPLORER_LOANS_LOAN_ID_SHOEBOX_USER_ID: '/explorer/loans/[loanId]/shoebox/[userShoeboxUserId]',
    LOAN_EXPLORER_SHOEBOX: '/explorer/shoebox',
    LOAN_EXPLORER_SHOEBOX_USER: '/explorer/shoebox/[userId]',
    LOAN_EXPLORER_TRASH: '/explorer/trash',
    LOAN_EXPLORER_TRASH_LOAN_ID: '/explorer/trash/[loanId]',
    LOAN_EXPLORER_TRASH_SHOEBOX: '/explorer/trash/shoebox',
} as const;
