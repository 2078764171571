import { j as We } from "./jsx-runtime-D0AQ1Mn7.js";
import { a as k, _ as O, b as Be } from "./index-CWsRuimy.js";
import * as ze from "react";
import { forwardRef as R, useContext as Ue, createContext as Ye, useState as _, useEffect as P, createElement as p, useRef as T, useMemo as je, useCallback as C } from "react";
import { b as D, $ as Z, a as Xe } from "./index-Bkga1U-V.js";
import { $ as le, a as ie } from "./index-BFOaq7n_.js";
import { $ as W, a as Ge } from "./index-BEhJEO-h.js";
import { $ as Ke } from "./index-DYqfa_Rt.js";
import { $ as Ve } from "./index-iYICMc2K.js";
import { u as qe, o as Ze, s as Je, f as Qe, a as et, b as tt, h as nt, c as ot, l as rt } from "./floating-ui.react-dom-88_vs_AG.js";
import { $ as ln } from "./index-4xMRqdcz.js";
import { $ as at } from "./index-BbCpSGJn.js";
import it from "react-dom";
import { $ as ue } from "./index-CaW1c6EJ.js";
import { $ as st } from "./index-BymTGE7C.js";
import { c as ct } from "./utils-CJ9afRe1.js";
const V = "dismissableLayer.update", dt = "dismissableLayer.pointerDownOutside", lt = "dismissableLayer.focusOutside";
let se;
const ut = /* @__PURE__ */ Ye({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), ft = /* @__PURE__ */ R((t, n) => {
  var e;
  const { disableOutsidePointerEvents: o = !1, onEscapeKeyDown: r, onPointerDownOutside: a, onFocusOutside: s, onInteractOutside: l, onDismiss: i, ...f } = t, c = Ue(ut), [d, v] = _(null), u = (e = d == null ? void 0 : d.ownerDocument) !== null && e !== void 0 ? e : globalThis == null ? void 0 : globalThis.document, [, h] = _({}), g = k(
    n,
    (b) => v(b)
  ), $ = Array.from(c.layers), [x] = [
    ...c.layersWithOutsidePointerEventsDisabled
  ].slice(-1), m = $.indexOf(x), y = d ? $.indexOf(d) : -1, w = c.layersWithOutsidePointerEventsDisabled.size > 0, E = y >= m, I = pt((b) => {
    const L = b.target, z = [
      ...c.branches
    ].some(
      (M) => M.contains(L)
    );
    !E || z || (a == null || a(b), l == null || l(b), b.defaultPrevented || i == null || i());
  }, u), A = $t((b) => {
    const L = b.target;
    [
      ...c.branches
    ].some(
      (M) => M.contains(L)
    ) || (s == null || s(b), l == null || l(b), b.defaultPrevented || i == null || i());
  }, u);
  return Ke((b) => {
    y === c.layers.size - 1 && (r == null || r(b), !b.defaultPrevented && i && (b.preventDefault(), i()));
  }, u), P(() => {
    if (d)
      return o && (c.layersWithOutsidePointerEventsDisabled.size === 0 && (se = u.body.style.pointerEvents, u.body.style.pointerEvents = "none"), c.layersWithOutsidePointerEventsDisabled.add(d)), c.layers.add(d), ce(), () => {
        o && c.layersWithOutsidePointerEventsDisabled.size === 1 && (u.body.style.pointerEvents = se);
      };
  }, [
    d,
    u,
    o,
    c
  ]), P(() => () => {
    d && (c.layers.delete(d), c.layersWithOutsidePointerEventsDisabled.delete(d), ce());
  }, [
    d,
    c
  ]), P(() => {
    const b = () => h({});
    return document.addEventListener(V, b), () => document.removeEventListener(V, b);
  }, []), /* @__PURE__ */ p(W.div, O({}, f, {
    ref: g,
    style: {
      pointerEvents: w ? E ? "auto" : "none" : void 0,
      ...t.style
    },
    onFocusCapture: D(t.onFocusCapture, A.onFocusCapture),
    onBlurCapture: D(t.onBlurCapture, A.onBlurCapture),
    onPointerDownCapture: D(t.onPointerDownCapture, I.onPointerDownCapture)
  }));
});
function pt(t, n = globalThis == null ? void 0 : globalThis.document) {
  const e = Z(t), o = T(!1), r = T(() => {
  });
  return P(() => {
    const a = (l) => {
      if (l.target && !o.current) {
        let f = function() {
          fe(dt, e, i, {
            discrete: !0
          });
        };
        const i = {
          originalEvent: l
        };
        l.pointerType === "touch" ? (n.removeEventListener("click", r.current), r.current = f, n.addEventListener("click", r.current, {
          once: !0
        })) : f();
      } else
        n.removeEventListener("click", r.current);
      o.current = !1;
    }, s = window.setTimeout(() => {
      n.addEventListener("pointerdown", a);
    }, 0);
    return () => {
      window.clearTimeout(s), n.removeEventListener("pointerdown", a), n.removeEventListener("click", r.current);
    };
  }, [
    n,
    e
  ]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => o.current = !0
  };
}
function $t(t, n = globalThis == null ? void 0 : globalThis.document) {
  const e = Z(t), o = T(!1);
  return P(() => {
    const r = (a) => {
      a.target && !o.current && fe(lt, e, {
        originalEvent: a
      }, {
        discrete: !1
      });
    };
    return n.addEventListener("focusin", r), () => n.removeEventListener("focusin", r);
  }, [
    n,
    e
  ]), {
    onFocusCapture: () => o.current = !0,
    onBlurCapture: () => o.current = !1
  };
}
function ce() {
  const t = new CustomEvent(V);
  document.dispatchEvent(t);
}
function fe(t, n, e, { discrete: o }) {
  const r = e.originalEvent.target, a = new CustomEvent(t, {
    bubbles: !1,
    cancelable: !0,
    detail: e
  });
  n && r.addEventListener(t, n, {
    once: !0
  }), o ? Ge(r, a) : r.dispatchEvent(a);
}
const pe = "Popper", [$e, he] = le(pe), [ht, be] = $e(pe), bt = (t) => {
  const { __scopePopper: n, children: e } = t, [o, r] = _(null);
  return /* @__PURE__ */ p(ht, {
    scope: n,
    anchor: o,
    onAnchorChange: r
  }, e);
}, mt = "PopperAnchor", vt = /* @__PURE__ */ R((t, n) => {
  const { __scopePopper: e, virtualRef: o, ...r } = t, a = be(mt, e), s = T(null), l = k(n, s);
  return P(() => {
    a.onAnchorChange((o == null ? void 0 : o.current) || s.current);
  }), o ? null : /* @__PURE__ */ p(W.div, O({}, r, {
    ref: l
  }));
}), me = "PopperContent", [gt, bn] = $e(me), xt = /* @__PURE__ */ R((t, n) => {
  var e, o, r, a, s, l, i, f;
  const { __scopePopper: c, side: d = "bottom", sideOffset: v = 0, align: u = "center", alignOffset: h = 0, arrowPadding: g = 0, avoidCollisions: $ = !0, collisionBoundary: x = [], collisionPadding: m = 0, sticky: y = "partial", hideWhenDetached: w = !1, updatePositionStrategy: E = "optimized", onPlaced: I, ...A } = t, b = be(me, c), [L, z] = _(null), M = k(
    n,
    (N) => z(N)
  ), [K, Pe] = _(null), S = at(K), Te = (e = S == null ? void 0 : S.width) !== null && e !== void 0 ? e : 0, te = (o = S == null ? void 0 : S.height) !== null && o !== void 0 ? o : 0, Ce = d + (u !== "center" ? "-" + u : ""), Ee = typeof m == "number" ? m : {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...m
  }, ne = Array.isArray(x) ? x : [
    x
  ], Oe = ne.length > 0, U = {
    padding: Ee,
    boundary: ne.filter(yt),
    // with `strategy: 'fixed'`, this is the only way to get it to respect boundaries
    altBoundary: Oe
  }, { refs: De, floatingStyles: oe, placement: _e, isPositioned: Y, middlewareData: H } = qe({
    // default to `fixed` strategy so users don't have to pick and we also avoid focus scroll issues
    strategy: "fixed",
    placement: Ce,
    whileElementsMounted: (...N) => ot(...N, {
      animationFrame: E === "always"
    }),
    elements: {
      reference: b.anchor
    },
    middleware: [
      Ze({
        mainAxis: v + te,
        alignmentAxis: h
      }),
      $ && Je({
        mainAxis: !0,
        crossAxis: !1,
        limiter: y === "partial" ? rt() : void 0,
        ...U
      }),
      $ && Qe({
        ...U
      }),
      et({
        ...U,
        apply: ({ elements: N, rects: ae, availableWidth: Me, availableHeight: Ne }) => {
          const { width: Fe, height: ke } = ae.reference, X = N.floating.style;
          X.setProperty("--radix-popper-available-width", `${Me}px`), X.setProperty("--radix-popper-available-height", `${Ne}px`), X.setProperty("--radix-popper-anchor-width", `${Fe}px`), X.setProperty("--radix-popper-anchor-height", `${ke}px`);
        }
      }),
      K && tt({
        element: K,
        padding: g
      }),
      wt({
        arrowWidth: Te,
        arrowHeight: te
      }),
      w && nt({
        strategy: "referenceHidden",
        ...U
      })
    ]
  }), [re, Ae] = ve(_e), j = Z(I);
  ie(() => {
    Y && (j == null || j());
  }, [
    Y,
    j
  ]);
  const Le = (r = H.arrow) === null || r === void 0 ? void 0 : r.x, Re = (a = H.arrow) === null || a === void 0 ? void 0 : a.y, Ie = ((s = H.arrow) === null || s === void 0 ? void 0 : s.centerOffset) !== 0, [Se, He] = _();
  return ie(() => {
    L && He(window.getComputedStyle(L).zIndex);
  }, [
    L
  ]), /* @__PURE__ */ p("div", {
    ref: De.setFloating,
    "data-radix-popper-content-wrapper": "",
    style: {
      ...oe,
      transform: Y ? oe.transform : "translate(0, -200%)",
      // keep off the page when measuring
      minWidth: "max-content",
      zIndex: Se,
      "--radix-popper-transform-origin": [
        (l = H.transformOrigin) === null || l === void 0 ? void 0 : l.x,
        (i = H.transformOrigin) === null || i === void 0 ? void 0 : i.y
      ].join(" ")
    },
    dir: t.dir
  }, /* @__PURE__ */ p(gt, {
    scope: c,
    placedSide: re,
    onArrowChange: Pe,
    arrowX: Le,
    arrowY: Re,
    shouldHideArrow: Ie
  }, /* @__PURE__ */ p(W.div, O({
    "data-side": re,
    "data-align": Ae
  }, A, {
    ref: M,
    style: {
      ...A.style,
      // if the PopperContent hasn't been placed yet (not all measurements done)
      // we prevent animations so that users's animation don't kick in too early referring wrong sides
      animation: Y ? void 0 : "none",
      // hide the content if using the hide middleware and should be hidden
      opacity: (f = H.hide) !== null && f !== void 0 && f.referenceHidden ? 0 : void 0
    }
  }))));
});
function yt(t) {
  return t !== null;
}
const wt = (t) => ({
  name: "transformOrigin",
  options: t,
  fn(n) {
    var e, o, r, a, s;
    const { placement: l, rects: i, middlewareData: f } = n, d = ((e = f.arrow) === null || e === void 0 ? void 0 : e.centerOffset) !== 0, v = d ? 0 : t.arrowWidth, u = d ? 0 : t.arrowHeight, [h, g] = ve(l), $ = {
      start: "0%",
      center: "50%",
      end: "100%"
    }[g], x = ((o = (r = f.arrow) === null || r === void 0 ? void 0 : r.x) !== null && o !== void 0 ? o : 0) + v / 2, m = ((a = (s = f.arrow) === null || s === void 0 ? void 0 : s.y) !== null && a !== void 0 ? a : 0) + u / 2;
    let y = "", w = "";
    return h === "bottom" ? (y = d ? $ : `${x}px`, w = `${-u}px`) : h === "top" ? (y = d ? $ : `${x}px`, w = `${i.floating.height + u}px`) : h === "right" ? (y = `${-u}px`, w = d ? $ : `${m}px`) : h === "left" && (y = `${i.floating.width + u}px`, w = d ? $ : `${m}px`), {
      data: {
        x: y,
        y: w
      }
    };
  }
});
function ve(t) {
  const [n, e = "center"] = t.split("-");
  return [
    n,
    e
  ];
}
const Pt = bt, Tt = vt, Ct = xt, Et = /* @__PURE__ */ R((t, n) => {
  var e;
  const { container: o = globalThis == null || (e = globalThis.document) === null || e === void 0 ? void 0 : e.body, ...r } = t;
  return o ? /* @__PURE__ */ it.createPortal(/* @__PURE__ */ p(W.div, O({}, r, {
    ref: n
  })), o) : null;
}), [G, mn] = le("Tooltip", [
  he
]), J = he(), Ot = "TooltipProvider", Dt = 700, q = "tooltip.open", [_t, Q] = G(Ot), At = (t) => {
  const { __scopeTooltip: n, delayDuration: e = Dt, skipDelayDuration: o = 300, disableHoverableContent: r = !1, children: a } = t, [s, l] = _(!0), i = T(!1), f = T(0);
  return P(() => {
    const c = f.current;
    return () => window.clearTimeout(c);
  }, []), /* @__PURE__ */ p(_t, {
    scope: n,
    isOpenDelayed: s,
    delayDuration: e,
    onOpen: C(() => {
      window.clearTimeout(f.current), l(!1);
    }, []),
    onClose: C(() => {
      window.clearTimeout(f.current), f.current = window.setTimeout(
        () => l(!0),
        o
      );
    }, [
      o
    ]),
    isPointerInTransitRef: i,
    onPointerInTransitChange: C((c) => {
      i.current = c;
    }, []),
    disableHoverableContent: r
  }, a);
}, ee = "Tooltip", [Lt, B] = G(ee), Rt = (t) => {
  const { __scopeTooltip: n, children: e, open: o, defaultOpen: r = !1, onOpenChange: a, disableHoverableContent: s, delayDuration: l } = t, i = Q(ee, t.__scopeTooltip), f = J(n), [c, d] = _(null), v = Ve(), u = T(0), h = s ?? i.disableHoverableContent, g = l ?? i.delayDuration, $ = T(!1), [x = !1, m] = Xe({
    prop: o,
    defaultProp: r,
    onChange: (A) => {
      A ? (i.onOpen(), document.dispatchEvent(new CustomEvent(q))) : i.onClose(), a == null || a(A);
    }
  }), y = je(() => x ? $.current ? "delayed-open" : "instant-open" : "closed", [
    x
  ]), w = C(() => {
    window.clearTimeout(u.current), $.current = !1, m(!0);
  }, [
    m
  ]), E = C(() => {
    window.clearTimeout(u.current), m(!1);
  }, [
    m
  ]), I = C(() => {
    window.clearTimeout(u.current), u.current = window.setTimeout(() => {
      $.current = !0, m(!0);
    }, g);
  }, [
    g,
    m
  ]);
  return P(() => () => window.clearTimeout(u.current), []), /* @__PURE__ */ p(Pt, f, /* @__PURE__ */ p(Lt, {
    scope: n,
    contentId: v,
    open: x,
    stateAttribute: y,
    trigger: c,
    onTriggerChange: d,
    onTriggerEnter: C(() => {
      i.isOpenDelayed ? I() : w();
    }, [
      i.isOpenDelayed,
      I,
      w
    ]),
    onTriggerLeave: C(() => {
      h ? E() : window.clearTimeout(u.current);
    }, [
      E,
      h
    ]),
    onOpen: w,
    onClose: E,
    disableHoverableContent: h
  }, e));
}, de = "TooltipTrigger", It = /* @__PURE__ */ R((t, n) => {
  const { __scopeTooltip: e, ...o } = t, r = B(de, e), a = Q(de, e), s = J(e), l = T(null), i = k(n, l, r.onTriggerChange), f = T(!1), c = T(!1), d = C(
    () => f.current = !1,
    []
  );
  return P(() => () => document.removeEventListener("pointerup", d), [
    d
  ]), /* @__PURE__ */ p(Tt, O({
    asChild: !0
  }, s), /* @__PURE__ */ p(W.button, O({
    // We purposefully avoid adding `type=button` here because tooltip triggers are also
    // commonly anchors and the anchor `type` attribute signifies MIME type.
    "aria-describedby": r.open ? r.contentId : void 0,
    "data-state": r.stateAttribute
  }, o, {
    ref: i,
    onPointerMove: D(t.onPointerMove, (v) => {
      v.pointerType !== "touch" && !c.current && !a.isPointerInTransitRef.current && (r.onTriggerEnter(), c.current = !0);
    }),
    onPointerLeave: D(t.onPointerLeave, () => {
      r.onTriggerLeave(), c.current = !1;
    }),
    onPointerDown: D(t.onPointerDown, () => {
      f.current = !0, document.addEventListener("pointerup", d, {
        once: !0
      });
    }),
    onFocus: D(t.onFocus, () => {
      f.current || r.onOpen();
    }),
    onBlur: D(t.onBlur, r.onClose),
    onClick: D(t.onClick, r.onClose)
  })));
}), ge = "TooltipPortal", [St, Ht] = G(ge, {
  forceMount: void 0
}), Mt = (t) => {
  const { __scopeTooltip: n, forceMount: e, children: o, container: r } = t, a = B(ge, n);
  return /* @__PURE__ */ p(St, {
    scope: n,
    forceMount: e
  }, /* @__PURE__ */ p(ue, {
    present: e || a.open
  }, /* @__PURE__ */ p(Et, {
    asChild: !0,
    container: r
  }, o)));
}, F = "TooltipContent", Nt = /* @__PURE__ */ R((t, n) => {
  const e = Ht(F, t.__scopeTooltip), { forceMount: o = e.forceMount, side: r = "top", ...a } = t, s = B(F, t.__scopeTooltip);
  return /* @__PURE__ */ p(ue, {
    present: o || s.open
  }, s.disableHoverableContent ? /* @__PURE__ */ p(xe, O({
    side: r
  }, a, {
    ref: n
  })) : /* @__PURE__ */ p(Ft, O({
    side: r
  }, a, {
    ref: n
  })));
}), Ft = /* @__PURE__ */ R((t, n) => {
  const e = B(F, t.__scopeTooltip), o = Q(F, t.__scopeTooltip), r = T(null), a = k(n, r), [s, l] = _(null), { trigger: i, onClose: f } = e, c = r.current, { onPointerInTransitChange: d } = o, v = C(() => {
    l(null), d(!1);
  }, [
    d
  ]), u = C((h, g) => {
    const $ = h.currentTarget, x = {
      x: h.clientX,
      y: h.clientY
    }, m = Wt(x, $.getBoundingClientRect()), y = Bt(x, m), w = zt(g.getBoundingClientRect()), E = Yt([
      ...y,
      ...w
    ]);
    l(E), d(!0);
  }, [
    d
  ]);
  return P(() => () => v(), [
    v
  ]), P(() => {
    if (i && c) {
      const h = ($) => u($, c), g = ($) => u($, i);
      return i.addEventListener("pointerleave", h), c.addEventListener("pointerleave", g), () => {
        i.removeEventListener("pointerleave", h), c.removeEventListener("pointerleave", g);
      };
    }
  }, [
    i,
    c,
    u,
    v
  ]), P(() => {
    if (s) {
      const h = (g) => {
        const $ = g.target, x = {
          x: g.clientX,
          y: g.clientY
        }, m = (i == null ? void 0 : i.contains($)) || (c == null ? void 0 : c.contains($)), y = !Ut(x, s);
        m ? v() : y && (v(), f());
      };
      return document.addEventListener("pointermove", h), () => document.removeEventListener("pointermove", h);
    }
  }, [
    i,
    c,
    s,
    f,
    v
  ]), /* @__PURE__ */ p(xe, O({}, t, {
    ref: a
  }));
}), [kt, vn] = G(ee, {
  isInside: !1
}), xe = /* @__PURE__ */ R((t, n) => {
  const { __scopeTooltip: e, children: o, "aria-label": r, onEscapeKeyDown: a, onPointerDownOutside: s, ...l } = t, i = B(F, e), f = J(e), { onClose: c } = i;
  return P(() => (document.addEventListener(q, c), () => document.removeEventListener(q, c)), [
    c
  ]), P(() => {
    if (i.trigger) {
      const d = (v) => {
        const u = v.target;
        u != null && u.contains(i.trigger) && c();
      };
      return window.addEventListener("scroll", d, {
        capture: !0
      }), () => window.removeEventListener("scroll", d, {
        capture: !0
      });
    }
  }, [
    i.trigger,
    c
  ]), /* @__PURE__ */ p(ft, {
    asChild: !0,
    disableOutsidePointerEvents: !1,
    onEscapeKeyDown: a,
    onPointerDownOutside: s,
    onFocusOutside: (d) => d.preventDefault(),
    onDismiss: c
  }, /* @__PURE__ */ p(Ct, O({
    "data-state": i.stateAttribute
  }, f, l, {
    ref: n,
    style: {
      ...l.style,
      "--radix-tooltip-content-transform-origin": "var(--radix-popper-transform-origin)",
      "--radix-tooltip-content-available-width": "var(--radix-popper-available-width)",
      "--radix-tooltip-content-available-height": "var(--radix-popper-available-height)",
      "--radix-tooltip-trigger-width": "var(--radix-popper-anchor-width)",
      "--radix-tooltip-trigger-height": "var(--radix-popper-anchor-height)"
    }
  }), /* @__PURE__ */ p(Be, null, o), /* @__PURE__ */ p(kt, {
    scope: e,
    isInside: !0
  }, /* @__PURE__ */ p(st, {
    id: i.contentId,
    role: "tooltip"
  }, r || o))));
});
function Wt(t, n) {
  const e = Math.abs(n.top - t.y), o = Math.abs(n.bottom - t.y), r = Math.abs(n.right - t.x), a = Math.abs(n.left - t.x);
  switch (Math.min(e, o, r, a)) {
    case a:
      return "left";
    case r:
      return "right";
    case e:
      return "top";
    case o:
      return "bottom";
    default:
      throw new Error("unreachable");
  }
}
function Bt(t, n, e = 5) {
  const o = [];
  switch (n) {
    case "top":
      o.push({
        x: t.x - e,
        y: t.y + e
      }, {
        x: t.x + e,
        y: t.y + e
      });
      break;
    case "bottom":
      o.push({
        x: t.x - e,
        y: t.y - e
      }, {
        x: t.x + e,
        y: t.y - e
      });
      break;
    case "left":
      o.push({
        x: t.x + e,
        y: t.y - e
      }, {
        x: t.x + e,
        y: t.y + e
      });
      break;
    case "right":
      o.push({
        x: t.x - e,
        y: t.y - e
      }, {
        x: t.x - e,
        y: t.y + e
      });
      break;
  }
  return o;
}
function zt(t) {
  const { top: n, right: e, bottom: o, left: r } = t;
  return [
    {
      x: r,
      y: n
    },
    {
      x: e,
      y: n
    },
    {
      x: e,
      y: o
    },
    {
      x: r,
      y: o
    }
  ];
}
function Ut(t, n) {
  const { x: e, y: o } = t;
  let r = !1;
  for (let a = 0, s = n.length - 1; a < n.length; s = a++) {
    const l = n[a].x, i = n[a].y, f = n[s].x, c = n[s].y;
    i > o != c > o && e < (f - l) * (o - i) / (c - i) + l && (r = !r);
  }
  return r;
}
function Yt(t) {
  const n = t.slice();
  return n.sort((e, o) => e.x < o.x ? -1 : e.x > o.x ? 1 : e.y < o.y ? -1 : e.y > o.y ? 1 : 0), jt(n);
}
function jt(t) {
  if (t.length <= 1) return t.slice();
  const n = [];
  for (let o = 0; o < t.length; o++) {
    const r = t[o];
    for (; n.length >= 2; ) {
      const a = n[n.length - 1], s = n[n.length - 2];
      if ((a.x - s.x) * (r.y - s.y) >= (a.y - s.y) * (r.x - s.x)) n.pop();
      else break;
    }
    n.push(r);
  }
  n.pop();
  const e = [];
  for (let o = t.length - 1; o >= 0; o--) {
    const r = t[o];
    for (; e.length >= 2; ) {
      const a = e[e.length - 1], s = e[e.length - 2];
      if ((a.x - s.x) * (r.y - s.y) >= (a.y - s.y) * (r.x - s.x)) e.pop();
      else break;
    }
    e.push(r);
  }
  return e.pop(), n.length === 1 && e.length === 1 && n[0].x === e[0].x && n[0].y === e[0].y ? n : n.concat(e);
}
const Xt = At, Gt = Rt, Kt = It, ye = Nt, Vt = Xt, qt = Gt, Zt = Kt, Jt = Mt, we = ze.forwardRef(({ className: t, sideOffset: n = 4, ...e }, o) => /* @__PURE__ */ We.jsx(
  ye,
  {
    ref: o,
    sideOffset: n,
    className: ct(
      "z-tooltip overflow-hidden rounded-md border border-gray-neutral-80 bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      t
    ),
    ...e
  }
));
we.displayName = ye.displayName;
const gn = Object.assign(qt, {
  Trigger: Zt,
  Content: we,
  Provider: Vt,
  Portal: Jt
});
export {
  gn as T
};
